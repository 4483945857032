.side-menu {
    >button {
        z-index         : 1000 !important;
        transform-origin: right center;
        transition      : transform 0.5s ease-in-out;

        &.open {
            &.desktop-view {
                transform: translateX(-300px) rotate(-90deg);
            }
            &.mobile-view {
                transform: translateX(-250px) rotate(-90deg);
            }

            transform-origin: right bottom;
        }

        &.closed {
            animation: animation1 1s linear 5 0.5s;
            transform: translateX(0) rotate(0);
        }
    }

    .contact-box {
        transition: transform 0.5s ease-in-out;

        &.open {
            transform: translateX(0);
        }

        &.closed {
            transform: translateX(100%);
        }
    }

    .edge {
        transition      : transform 0.5s ease-in-out;
        transform-origin: left bottom;

        &.open {
            &.desktop-view {
                transform: translateX(-300px) rotate(-90deg);
            }
            &.mobile-view {
                transform: translateX(-250px) rotate(-90deg);
            }
        }

        &.closed {
            transform: translateX(0) rotate(0);
        }
    }
}

@keyframes animation1 {
    0% {
        scale: 1
    }

    50% {
        scale: 1.25
    }

    100% {
        scale: 1
    }
}

@keyframes moveAnimation {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-300px);
    }
}